<template>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-card class="cardClassDetailForm" title="작업계획 정보">
          <template slot="card-button">
            <q-btn-group outline >
              <c-btn v-if="editable&&!disabled" label="저장" icon="save" />
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <c-mobile-table
                ref="grid1"
                title="작업자"
                topBorderClass="topcolor-orange"
                :columns="grid1.columns"
                :data="grid1.data"
                :gridHeightAuto="true"
                :isTitle="true"
                :editable="editable&&!disabled"
                :hideBottom="true"
                :isExcelDown="false"
                :filtering="false"
                :columnSetting="false"
                selection="multiple"
                rowKey="userId"
              >
                <template slot="table-button">
                  <q-btn-group outline >
                    <c-btn v-if="editable&&!disabled" label="추가" icon="add"/>
                    <c-btn v-if="editable&&!disabled" label="삭제" icon="remove" />
                  </q-btn-group>
                </template>
              </c-mobile-table>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <c-mobile-table
                ref="grid2"
                title="작업자재"
                topBorderClass="topcolor-orange"
                :columns="grid2.columns"
                :data="grid2.data"
                :gridHeightAuto="true"
                :isTitle="true"
                :editable="editable&&!disabled"
                :hideBottom="true"
                :isExcelDown="false"
                :filtering="false"
                :columnSetting="false"
                selection="multiple"
                rowKey="userId"
              >
                <template slot="table-button">
                  <q-btn-group outline >
                    <c-btn v-if="editable&&!disabled" label="추가" icon="add"/>
                    <c-btn v-if="editable&&!disabled" label="삭제" icon="remove" />
                  </q-btn-group>
                </template>
              </c-mobile-table>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <c-mobile-table
                ref="grid3"
                title="작업용역"
                topBorderClass="topcolor-orange"
                :columns="grid3.columns"
                :data="grid3.data"
                :gridHeightAuto="true"
                :isTitle="true"
                :editable="editable&&!disabled"
                :hideBottom="true"
                :isExcelDown="false"
                :filtering="false"
                :columnSetting="false"
                selection="multiple"
                rowKey="userId"
              >
                <template slot="table-button">
                  <q-btn-group outline >
                    <c-btn v-if="editable&&!disabled" label="추가" icon="add"/>
                    <c-btn v-if="editable&&!disabled" label="삭제" icon="remove" />
                  </q-btn-group>
                </template>
              </c-mobile-table>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <c-mobile-table
                ref="grid4"
                title="작업공구"
                topBorderClass="topcolor-orange"
                :columns="grid4.columns"
                :data="grid4.data"
                :gridHeightAuto="true"
                :isTitle="true"
                :editable="editable&&!disabled"
                :hideBottom="true"
                :isExcelDown="false"
                :filtering="false"
                :columnSetting="false"
                selection="multiple"
                rowKey="userId"
              >
                <template slot="table-button">
                  <q-btn-group outline >
                    <c-btn v-if="editable&&!disabled" label="추가" icon="add"/>
                    <c-btn v-if="editable&&!disabled" label="삭제" icon="remove" />
                  </q-btn-group>
                </template>
              </c-mobile-table>
            </div>
          </template>
        </c-card>
      </div>
  </div>
</template>

<script>
// import selectConfig from '@/js/selectConfig';
export default {
  name: 'outernal-info',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        id: '',
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      editable: true,
      grid1: {
        columns: [
          {
            name: 'col1',
            field: 'col1',
            label: '작업자',
            align: 'center',
            type: 'user',
            userId: 'userId',
            sortable: false,
            colClass: 6,
          },
          {
            name: 'col2',
            field: 'col2',
            label: '작업시작일',
            align: 'center',
            type: 'date',
            style: 'width: 200px',
            setHeader: true,
            sortable: false,
            colClass: 6,
          },
          {
            name: 'col3',
            field: 'col3',
            label: '시작시간',
            align: 'center',
            type: 'datetime',
            style: 'width: 150px',
            datetimeType: 'time', 
            setHeader: true,
            sortable: false,
            colClass: 6,
          },
          {
            name: 'col4',
            field: 'col4',
            label: '종료시간',
            align: 'center',
            type: 'datetime',
            style: 'width: 150px',
            datetimeType: 'time', 
            setHeader: true,
            sortable: false,
            colClass: 6,
          },
        ],
        data: [],
        height: '300px',
      },
      grid2: {
        columns: [
          {
            name: 'col1',
            field: 'col1',
            label: '자재',
            align: 'center',
            type: 'text',
            sortable: false,
          },
          {
            name: 'col2',
            field: 'col2',
            label: '설명',
            align: 'left',
            type: 'text',
            style: 'width: 300px',
            sortable: false,
          },
          {
            name: 'col3',
            field: 'col3',
            label: '단가',
            type: 'number',
            style: 'width: 100px',
            sortable: false,
            colClass: 4,
          },
          {
            name: 'col4',
            field: 'col4',
            label: '수량',
            type: 'number',
            style: 'width: 80px',
            sortable: false,
            colClass: 4,
          },
          {
            name: 'col5',
            field: 'col5',
            label: '소계',
            type: 'cost',
            style: 'width: 120px',
            sortable: false,
            colClass: 4,
          },
        ],
        data: [],
        height: '300px',
      },
      grid3: {
        columns: [
          {
            name: 'col1',
            field: 'col1',
            label: '업체명',
            align: 'center',
            type: 'vendor',
            sortable: false,
          },
          {
            name: 'col2',
            field: 'col2',
            label: '작업내용',
            align: 'left',
            type: 'text',
            style: 'width: 300px',
            sortable: false,
          },
          {
            name: 'col3',
            field: 'col3',
            label: '투입인원',
            type: 'number',
            style: 'width: 100px',
            sortable: false,
            colClass: 6,
          },
          {
            name: 'col4',
            field: 'col4',
            label: '용역비용',
            type: 'number',
            style: 'width: 140px',
            sortable: false,
            colClass: 6,
          },
        ],
        data: [],
        height: '300px',
      },
      grid4: {
        columns: [
          {
            name: 'col1',
            field: 'col1',
            label: '공구명',
            align: 'center',
            type: 'text',
            sortable: false,
            colClass: 6,
          },
          {
            name: 'col2',
            field: 'col2',
            label: '사용 개수',
            align: 'center',
            type: 'number',
            style: 'width: 140px',
            sortable: false,
            colClass: 6,
          },
        ],
        data: [],
        height: '300px',
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.grid1.data = [
        {
          col1: '관리자',
          userId: 'U000000001',
          col2: '2022-11-20',
          col3: '14:00',
          col4: '16:30',
        },
        {
          col1: '홍길동',
          userId: 'U000000002',
          col2: '2022-11-20',
          col3: '14:00',
          col4: '16:30',
        },
      ]
      this.grid2.data = [
        {
          col1: '부품 #0012',
          col2: '특정부위에 속한 부품',
          col3: '200000',
          col4: '2',
          col5: '400000',
        },
      ]
      this.grid3.data = [
        {
          col1: 'V232213',
          col2: '작업을 위한 투입',
          col3: '3',
          col4: '1800000',
        },
      ]
      this.grid4.data = [
        {
          col1: '스패너',
          col2: '5',
        },
        {
          col1: '용접기',
          col2: '1',
        },
      ]
    },
  }
};
</script>